import React from 'react';
import Layout from '../components/global/Layout';
import HeroSection from '../components/sections/Hero';
import AboutSection from '../components/sections/AboutMe';

const IndexPage = () => {
	return (
		<Layout>
			<main>
				<HeroSection />
				<AboutSection />
			</main>
		</Layout>
	);
};

export default IndexPage;

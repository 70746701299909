import React from 'react';

const AboutMe = () => {
	return (
		<section className="section-padding">
			<div className="container">
				<div className="row">
					<div className="col-lg-9">
						<h2 className="section-heading">about me</h2>
						<p>
							I am an enthusiastic web developer using technologies / frameworks such as HTML5, CSS3,
							JavaScript, TypeScript PHP, React, WordPress, NextJS, Gatsby, NodeJS and more.
						</p>
						<p>
							I am also passionate about accessibility, e-commerce and SEO. I am currently advancing my
							TypeScript skills and prepping for a CPACC certification exam .
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutMe;

import React from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
	background-color: #1f0e49;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='46' height='46' fill-opacity='0.47' fill='%2326115a'/%3E%3C/svg%3E");
	/* background by SVGBackgrounds.com */
	padding-top: 50px;
	padding-bottom: 50px;
	position: relative;
	z-index: 2;

	h1,
	h2 {
		color: white;
	}

	h1 {
		font-size: 84px;
		margin-bottom: 10px;
	}

	h2 {
		font-size: 34px;
		line-height: 1.2;
	}

	.blob {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;

		@media screen and (max-width: 800px) {
			display: none;
		}
	}

	.blob-grid {
		position: absolute;
		width: 160px;
		top: 20px;
		left: 45%;
		@media screen and (max-width: 767px) {
			left: 65%;
		}
	}

	span {
		color: #fdf4b4;
		font-family: PT Serif;
	}
`;

const Hero = () => {
	return (
		<HeroSection>
			<svg className="blob-grid" viewBox="0 0 700 400" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="40.5" cy="40.5" r="40.5" fill="#EE3364" />
				<circle cx="328.5" cy="40.5" r="40.5" fill="#EE3364" />
				<circle cx="232.5" cy="40.5" r="40.5" fill="#EE3364" />
				<circle cx="136.5" cy="40.5" r="40.5" fill="#EE3364" />
				<circle cx="40.5" cy="136.5" r="40.5" fill="#EE3364" />
				<circle cx="328.5" cy="136.5" r="40.5" fill="#EE3364" />
				<circle cx="232.5" cy="136.5" r="40.5" fill="#EE3364" />
				<circle cx="136.5" cy="136.5" r="40.5" fill="#EE3364" />
				<circle cx="40.5" cy="232.5" r="40.5" fill="#EE3364" />
				<circle cx="328.5" cy="232.5" r="40.5" fill="#EE3364" />
				<circle cx="232.5" cy="232.5" r="40.5" fill="#EE3364" />
				<circle cx="136.5" cy="232.5" r="40.5" fill="#EE3364" />
			</svg>

			<svg className="blob" width="400" height="600" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
				<g transform="translate(300,300)">
					<path
						d="M50.8,-90.9C69.1,-67.2,89.6,-58.7,121.4,-40.4C153.2,-22,196.4,6.2,192.3,26.9C188.2,47.6,136.8,60.8,103.7,78.4C70.6,96,55.9,118.1,30.9,142.3C5.9,166.6,-29.3,193.2,-66.3,195.9C-103.2,198.7,-141.9,177.6,-168.5,146.3C-195,115.1,-209.3,73.6,-195.6,40C-181.9,6.5,-140.1,-19.2,-121.7,-54C-103.3,-88.8,-108.4,-132.7,-92.1,-157.3C-75.7,-181.8,-37.8,-186.9,-10.8,-170.1C16.2,-153.2,32.4,-114.5,50.8,-90.9Z"
						fill="#fdf4b4"
					/>
				</g>
			</svg>

			<div className="container">
				<h1>
					Hello<span>.</span>
				</h1>
				<h2>
					i am PJ, <br /> a web developer
				</h2>
			</div>
		</HeroSection>
	);
};

export default Hero;
